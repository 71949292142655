@import "bulma/sass/utilities/_all.sass";
@import "bulma/sass/base/helpers.sass";
@import "bulma/sass/base/minireset.sass";
@import "bulma/sass/components/navbar.sass";
@import "bulma/sass/components/tabs.sass";
@import "bulma/sass/components/modal.sass";
@import "bulma/sass/elements/_all.sass";
@import "bulma/sass/grid/columns.sass";
@import "bulma/sass/form/_all.sass";
@import "variables";
@import "mixins";

html {
  scroll-behavior: smooth;
  color: $normal;
}

* {
  box-sizing: border-box;
}

button:not([disabled]) {
  cursor: pointer;
  touch-action: manipulation;
}

.clickable {
  cursor: pointer;
  touch-action: manipulation;
}

.d-block {
  display: block;
}

.ll-btn {
  height: $btn_height;
  background-color: white;
  text-align: center;
  border: 0;
  transition-duration: 0.15s;
  transition-property: color, background-color, border-color;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.7em;
  cursor: pointer;

  &:hover {
    background-color: #e0e0e0;
  }

  & > * {
    margin: auto;
  }
}

.v-center {
  padding-top: 1px;
  padding-bottom: 1px;
}

a.ll-btn {
  text-decoration: none;
}

.ll-grey-left-border {
  border-left: 1px solid #c8c8c8;
}

.ll-grey-top-border {
  border-top: 1px solid #c8c8c8;
}

.ll-btn-primary {
  background-color: #fdc300;
  color: white;

  &:hover {
    background-color: #daa800;
  }
}

.ll-btn-grey {
  color: white;
  background: $grey2;
  &:hover {
    background: $light;
  }
}

.lh-15 {
  line-height: 1.5;
}

.lh-143 {
  line-height: 1.43;
}

.ll-btn-primary-hollow {
  box-sizing: border-box;
  background: none;
  border: 2px solid #fec300;
  border-radius: 8px;
  color: #fec300;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;

  &:hover {
    border-color: transparent;
    background-color: #fff;
  }
}

.ll-shadow-primary {
  box-shadow: 0 5px 20px -6px rgba(102, 94, 56, 0.5);
}

.ll-shadow-danger {
  box-shadow: 0 6px 10px -5px rgba(102, 94, 56, 0.5);
}

.ll-btn-square {
  width: $btn_height + 1;
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-width {
  display: flex;
  width: 100%;
  > * {
    flex: 1;
  }
}

.d-none {
  display: none !important;
}

.m-auto {
  margin: 0 auto;
}

.flex-spacer {
  flex: 1000;
}

.text-center {
  text-align: center;
}

.text-dark,
.text-dark a {
  color: $dark;
}

.text-normal,
*:link,
*:visited {
  color: $normal;
}

.text-light,
.text-light a {
  color: $light;
}

.mg-t-20 {
  margin-top: 20px;
}

.mg-t-30 {
  margin-top: 30px;
}

.mg-t-70 {
  margin-top: 70px;
}

.mg-t-100 {
  margin-top: 100px;
}

.rnd-8 {
  border-radius: 8px;
}

.bg-grey {
  background-color: $grey;
}

.text-white {
  color: #fff;
}

.text-golder {
  color: $golder;
}

.text-green {
  color: $green;
}

.text-red {
  color: $red;
}

.text-s32 {
  font-size: 32px;
}

.text-s28 {
  font-size: 28px;
}

.text-s20 {
  font-size: 20px;
}

.text-s16 {
  font-size: 16px;
}

.text-s14 {
  font-size: 14px;
}

.text-s12 {
  font-size: 12px;
}

.text-bold {
  font-weight: bold;
}

.h-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-left {
  text-align: left;
}

.no-dec,
.no-dec * {
  text-decoration: none;
  list-style-type: none;
}

.ls-2 {
  letter-spacing: 2px;
}

.fw-5 {
  font-weight: 500;
}

#contact {
  background-color: #fff;
  width: 100%;
}

#contact-inner {
  padding: 90px 0;
  border-bottom: 1px solid #3c2d1a;
  background-color: transparent;
}

#contact-title {
  margin-bottom: 20px;
}

.contact-item {
  display: block;
}

#contact .contact-item + .contact-item {
  margin-top: 10px;
  @include md {
    margin-top: 0;
  }
}

.w100 {
  width: 100%;
}

#footer {
  width: 100%;
  background-color: #fff;
  padding: 0 25px;
}

#footer-links {
  border-bottom: 1px solid #c8c8c8;
  padding: 80px 0 90px;
}

#footer-slogan {
  margin-top: 5px;
  width: 200px;
}

#footer-logo {
  margin-left: -4px;
}

.footer-logo-container {
  margin-bottom: 40px;
  @include lg {
    margin-bottom: 0;
  }
}

.footer-link:not(:first-child) {
  margin-top: 15px;
}

#copyright {
  margin-top: 30px;
  margin-bottom: 30px;
}

.flex-grow-1 {
  flex-grow: 1;
}

.p-rel {
  position: relative;
}

.maxw-820 {
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;
}

.maxw-1024 {
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
}

.noscroll {
  overflow: hidden;
}

#overlay {
  min-width: 100vw;
  min-height: 100vh;
  position: fixed;
  background-color: transparent;
  left: 0;
  visibility: hidden;
  z-index: 100;
  transition: visibility 0.2s, background-color 0.2s ease-out;
  .active & {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.3);
  }
}

#side-nav {
  position: absolute;
}

#side-nav-content {
  height: 100vh;
  width: 300px;
  position: fixed;
  left: 100vw;
  background: #151515;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-duration: 0.3s;
  transition-property: visibility, left;
  visibility: hidden;
  z-index: 101;
  .active & {
    visibility: visible;
    left: calc(100vw - 300px);
  }
}

#side-nav-close {
  margin-top: 21px;
  margin-right: 26px;
  display: inline-block;
  font-size: 16px;
  width: 24px;
  height: 24px;
  transition: color 0.1s;
  cursor: pointer;
  &:hover {
    color: $golder;
  }
}

.pointer {
  cursor: pointer;
}

.side-nav-item {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 4px;
  text-align: center;
  color: $white;
  display: block;
  text-decoration: none;
  transition: color 0.1s;
  &:not(:first-child) {
    margin-top: 30px;
  }
  &:hover {
    color: $golder;
  }
}

.side-nav-lang {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 2px;
  text-align: center;
  color: $white;
  display: block;
  text-decoration: none;
  transition: color 0.1s;
  &:not(:first-child) {
    margin-top: 20px;
  }
  &:hover,
  &.active {
    color: $golder;
  }
}

#side-nav-top {
  height: 54px;
  color: white;
}

#side-nav-sep {
  margin: 28px 50px 17px;
  background-color: #676767;
  height: 1px;
}

.navbar-dropdown {
  background-color: #222;
  border: none;
}
.navbar.is-transparent .navbar-dropdown a.navbar-item:focus,
.navbar.is-transparent .navbar-dropdown a.navbar-item:hover {
  color: $golder;
}

.control-cell {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  align-content: center;
  background: #fff;
  border-color: $grey2;
}

.h100,
.full-height {
  height: 100%;
}

.ll-sel-line {
  appearance: none;
  padding-right: 1.25em;
  border: none;
  background: transparent;
  display: inline;
}

.select-wrapper {
  display: inline-flex;
  &__underline {
    border-bottom: 1px solid #616161;
  }
}

.sel-triangle {
  border-top: 5px solid #616161;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: -1em;
  transition: border-top-color 0.1s;
}

.select-wrapper:hover > .sel-triangle {
  border-top: 5px solid #fec300;
}

.flatpickr-current-month {
  font-size: 100%;
}

.date-input {
  appearance: none;
  border: none;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

#search-expand {
  &.active {
    background: $grey;
    & > .fa-plus {
      transform: rotate(45deg);
    }
  }
  & > .fa-plus {
    transition: transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  }
}

.flatpickr-input {
  background: transparent;
}

.ll-section-title {
  font-size: 28px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 4px;
  color: #3c2d1a;
  margin-bottom: 20px;
  margin-top: 120px;
}

.ll-section-subtitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 2px;
  color: #616161;
}

body,
button,
input,
select,
textarea,
.pure-g [class*="pure-u"] {
  font-family: "segoe ui", "Noto Sans CJK TC", "microsoft jhenghei",
    "microsoft mhei", stheititc, sans-serif;
}

div {
  box-sizing: border-box;
}

.navbar-item,
.navbar-link {
  text-decoration: none;
}

.nav-container {
  box-shadow: 0 1px 4px 0 rgba(102, 94, 56, 0.5);
  background: white;
  width: 100%;
  position: relative;
  z-index: 1;
}

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
}

.lined-section {
  padding-top: 30px;
  padding-bottom: 10px;
}

.lined-section-heading {
  padding-bottom: 8px;
  border-bottom: 1px solid $grey2;
}

.lined-section-content {
  padding-top: 15px;
}

.flex-bl {
  display: flex;
  align-items: baseline;
}

:lang(zh-TW),
:lang(ja),
:lang(ko) {
  &.cjk-space1 {
    letter-spacing: 1px;
  }
  &.cjk-space2 {
    letter-spacing: 2px;
  }
  &.cjk-space4 {
    letter-spacing: 4px;
  }
}

.pm-container {
  width: 180px;
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.ll-shadow {
  box-shadow: 0 5px 20px -6px rgba(102, 94, 56, 0.5);
}

.ll-overlay {
  position: fixed;
  overflow-y: auto;
  overscroll-behavior: contain;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 150;
  display: none;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.ll-overlay-visible {
  display: block;
  opacity: 1;
}

.ll-overlay-bg {
  background: rgba(60, 45, 26, 0.3);
  padding-top: 44px;
  padding-bottom: 44px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
}

.ll-overlay-wrap {
  position: relative;
  margin-left: 16px;
  margin-right: 16px;
  overflow: hidden;
  flex-basis: 1024px;
}

.button.is-lala {
  background: $gold;
  color: white;
}

.button.is-ll-danger {
  background: $red;
  color: white;
}

.button.is-ll-danger:hover,
.button.is-ll-danger.is-hovered {
  background-color: $red3;
}

.button.is-lala[disabled],
fieldset[disabled] .button.is-lala {
  background-color: #ffdd57;
  border-color: transparent;
  box-shadow: none;
}

.navbar-link:not(.is-arrowless)::after {
  border-color: $gold;
}

.lr-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.lr-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.lr-16 {
  padding-left: 10px;
  padding-right: 10px;
}

.select:not(.is-multiple):not(.is-loading)::after {
  border-color: $normal;
}

.h54 {
  height: 54px;
}

.a {
  text-decoration: underline;
  cursor: pointer;
}

.loading-overlay {
  position: fixed;
  z-index: 200;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: white;
  transition: opacity 200ms ease;

  &:not(.visible) {
    opacity: 0;
    pointer-events: none;
  }
}

// spinner
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: $gold;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}
// end spinner

.debug {
  &,
  & * {
    outline: 1px solid #3333;
    background: #1111;
  }
}

.topright-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 16px;
  cursor: pointer;
  background: url("/assets/img/ox.svg") bottom left no-repeat;
}

.ll-pills-container {
  display: flex;
  font-size: 16px;
  line-height: 1.5;
  padding-left: 16px;
  padding-right: 16px;
  @include md {
    padding-left: 58px;
    padding-right: 58px;
  }
}

.ll-pill {
  text-decoration: none;
  display: flex;
  height: 52px;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  &.active {
    color: $golder;
    border-bottom: 4px solid $gold;
  }
}

.req-star::after {
  color: #ff3b30;
  content: "*";
}

.generic-container {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
}
