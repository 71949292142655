$screen-lg-min: 1024px;
$screen-md-min: 768px;

@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin not-md {
  @media not all and (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin cjk {
  :lang(zh-TW),
  :lang(ja),
  :lang(ko) {
    @content;
  }
}