$btn_height: 54px;
$golder: #fec300;
$gold: #fdc300;
$dark: #3c2d1a;
$normal: #616161;
$light: #9e9e9e;
$red: #ff3b30;
$red2: #ff5e55;
$red3: #da4c44;
$green:  #1cd293;
$grey: #ededed;
$grey2: #c8c8c8;
$grey3: #f7f7f5;
$grey4: #f5f5f5;
$white: #fff;
